<template>
	<div class="relatedPosts noisy" data-debugName="relatedPosts" v-if="posts.length">		
		
		<div class="border border--bottom row" :class="titleRowClasses">
			<h3 class="hSpace hSpace--big vSpace vSpace--default col">
				{{$root.getTranslatedValue(title)}}
			</h3>			
		</div>
			
		<div class="border border--bottom row">
			<div class="relatedPosts__posts col">
				<div 	class="relatedPosts__post border border--right border--bottom"
						v-for="post in posts" 
						:key="post.id">
					
					<MhImage class="relatedPosts__postImage" :imageObject="post.acf.image" mode="cover"></MhImage>
					
					<div class="relatedPosts__postFooter hSpace hSpace--small vSpace vSpace--postTeaser">
						<h4 class="relatedPosts__postTitle">
							{{$root.getTranslatedAcfValue(post.acf, 'postTitle')}}					
						</h4>
						<div class="relatedPosts__postSubTitle">
							<div class="font font--sizeSmall">
								<span class="font font--sizeSmall">
									<template v-if="getFilterPostById(post.acf.category)">
										{{$root.getTranslatedAcfValue( getFilterPostById(post.acf.category).acf, 'postTitle')}} ·
									</template>
									<template v-if="getFilterPostById(post.acf.semester)">
										{{$root.getTranslatedAcfValue( getFilterPostById(post.acf.semester).acf, 'postTitle')}}
									</template>
								</span>
							</div>
						</div>					
					</div>
					
					<router-link class="relatedPosts__postLink noArrow" :to="$root.getSingleLink( post )"></router-link>
					
				</div>
			</div>
		</div>
	
	</div>
</template>

<script>
	import MhImage from '@/components/MhImage/MhImage.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js';
	
	export default {
		name: 'RelatedPosts',
		components: {
			MhImage,
		},
		mixins: [ RestHandler ],		
		props: {
			title: String,
			postIds: Array,
			endPoint: String,
		},
		data() {
			return {
				posts: [],
				filterPosts: [],
			}
		},
		computed: {
			titleRowClasses(){
				let classes = []
				
				if( this.$route.name == 'ProgrammDetailView' ) classes.push('row--green')
				if( this.$route.name == 'ProjectDetailView' ) classes.push('row--yellow')
				
				return classes
			},
		},
		methods: {
			getFilterPostById( id ) {
				const filterPosts = this.filterPosts				
				return this._.findLast(filterPosts, {id})
			},				
		},
		mounted() {

			// fetch posts and all related filterPosts
			this.restHandler__fetch({
				action : 'GET',				
				route : '/wp-json/mh/v1/posts',
				params : {
					postIds : this.postIds,
				},
				callback : (response) => {
					// asign fetched post
					this.posts = response.data.result

					// fetch all related filterPosts
					this.restHandler__fetch({
						action : 'GET',				
						route : '/wp-json/mh/v1/usedFilters',
						params : {
							postIds : this._.map(this.posts, 'id'),
						},
						callback : (response) => {
							// asign fetched posts
							this.filterPosts = response.data.result
							
							// remove loading state
							this.isLoading = false
						}
					})
				}
			})
		
		},
	}
</script>

<style lang="less" scoped>
	@import "../less/includes/grid.include.less";
	
	.relatedPosts__posts {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -1px;
	}
	.relatedPosts__post {			
		position: relative;
		width: 25%;
		display: flex;
		flex-direction: column;		
		background-color: white;
	}
	.relatedPosts__postImage {
		padding-bottom: 60%;
	}
	.relatedPosts__postFooter {
		flex-grow: 1;
	}
	.relatedPosts__postLink {
		position: absolute;
		top: 0; left: 0;
		height: 100%; width: 100%;
	}
	
	@media @mediaQuery__sm {
		.relatedPosts__post {			
			width: 100%;
		}
		.relatedPosts__postSubTitle {
			margin-top: 0.2em;
		}
	}
</style>
