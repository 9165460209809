<template>
	<MhView :isLoading="isLoading">
		
		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>			
		
		<div class="programmPost" v-if="post">		
			
			<h2 class="border border--bottom row" >
				<div class="hSpace hSpace--big vSpace vSpace--default col">
					<span class="programmPost__postTitle font font--sizeBig">
						{{$root.getTranslatedValue('Lehre')}} · {{$root.getTranslatedAcfValue( post.acf, 'postTitle')}}
					</span>
					<br v-if="$root.isSmallDevice" />
					<span class="programmPost__postRubrik" >
						<template v-if="getFilterPostById(post.acf.category)">
							{{$root.getTranslatedAcfValue( getFilterPostById(post.acf.category).acf, 'postTitle')}} ·
						</template>
						<template v-if="getFilterPostById(post.acf.semester)">
							{{$root.getTranslatedAcfValue( getFilterPostById(post.acf.semester).acf, 'postTitle')}}
						</template>
					</span>
				</div>
			</h2>

			<div class="border border--bottom row">
				<div class="col col--3of12 col--sm-12of12" :class="{'border border--right' : !$root.isSmallDevice}">					
					<MhImage :imageObject="image" v-for="image in post.acf.images" :key="image.id"></MhImage>
				</div>
				<div class="hSpace hSpace--medium vSpace vSpace--default col col--6of12 col--sm-12of12" v-html="$root.getTranslatedAcfValue( post.acf, 'text')" :class="{'border border--right' : !$root.isSmallDevice}"></div>
				<div class="hSpace hSpace--medium vSpace vSpace--default col col--3of12 col--sm-12of12">
					<AsidePairs :post="post"></AsidePairs>
				</div>
			</div>

			<template v-if="post">
				<RelatedPosts title="Verwandte Lehrveranstaltungen" :postIds="post.acf.relatedPosts__programmPosts" endPoint="programmPosts"></RelatedPosts>			
				<RelatedPosts title="Projekte" :postIds="post.acf.relatedPosts__projectPosts" endPoint="projectPosts"></RelatedPosts>
			</template>
			
		</div>
		
	</MhView>
</template>

<script>
	import MhView from '@/components/MhView/MhView.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import AsidePairs from '@/components/AsidePairs.vue'
	import RelatedPosts from '@/components/RelatedPosts.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'; 
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'programmDetailView',
		components: {
			MhImage,
			MhView,
			AsidePairs,
			RelatedPosts,
			MobilePageTitle,
		},		
		mixins: [ RestHandler ],
		data() {
			return {
				isLoading: true,
				post: null,
				filterPosts: [],
			}
		},
		methods: {
			getFilterPostById( id ) {
				const filterPosts = this.filterPosts				
				return this._.findLast(filterPosts, {id})
			},				
		},
		mounted () {

			// fetch post and all related filterPosts
			this.restHandler__fetch({
				action : 'GET',				
				route : '/wp-json/wp/v2/programmPosts',
				params : {
					slug : this.$route.params.postSlug,
				},
				callback : (response) => {
					// asign fetched post
					this.post = response.data[0]
					
					// fetch all related filterPosts
					this.restHandler__fetch({
						action : 'GET',				
						route : '/wp-json/mh/v1/usedFilters',
						params : {
							postId : this.post.id,
						},
						callback : (response) => {
							// asign fetched posts
							this.filterPosts = response.data.result
							
							// remove loading state
							this.isLoading = false
						}
					})
					
				}
			})

		},		
	}
</script>

<style lang="less" scoped>	
	.programmPost__postTitle {
		padding-right: 1em;
		display: inline-block;
	}
	.programmPost__postRubrik {
		//display: inline-block;
		//padding-left: 2em;
	}
	.mhImage + .mhImage {
		border-top: 1px solid;
	}
</style>
