import { render, staticRenderFns } from "./RelatedPosts.vue?vue&type=template&id=d4b02998&scoped=true&"
import script from "./RelatedPosts.vue?vue&type=script&lang=js&"
export * from "./RelatedPosts.vue?vue&type=script&lang=js&"
import style0 from "./RelatedPosts.vue?vue&type=style&index=0&id=d4b02998&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4b02998",
  null
  
)

component.options.__file = "RelatedPosts.vue"
export default component.exports